import { combineReducers } from '@reduxjs/toolkit'

import { USER_API_REDUCER_KEY, userApi } from '@store/services/user'
import { PRODUCTS_API_REDUCER_KEY, productsApi } from '@store/services/products'
import { BASKET_API_REDUCER_KEY, basketApi } from '@store/services/basket'
import { NOTIFICATIONS_API_REDUCER_KEY, notificationsApi } from '@store/services/notifications'
import { PARTNER_API_REDUCER_KEY, partnerApi } from '@store/services/partner'
import { PAYMENT_API_REDUCER_KEY, paymentApi } from '@store/services/payment'
import { ONBOARDING_API_REDUCER_KEY, onboardingApi } from '@store/services/onboarding'
import { TASK_API_REDUCER_KEY, taskApi } from '@store/services/tasks'
import { BOOKING_API_REDUCER_KEY, bookingApi } from '@store/services/booking'
import { ECOMMERCE_API_REDUCER_KEY, ecommerceApi } from '@store/services/ecommerce'
import appReducer from './slices/appSlice'
import onboardingReducer from './slices/onboardingSlice'
import basketReducer from './slices/basketSlice'
import modalReducer from './slices/modalSlice'
import alertReducer from './slices/alertSlice'
import cartSlice from './slices/cartSlice'
import lawdocsSlice from './slices/lawdocsSlice'
import { DOCUMENTS_API_REDUCER_KEY, documentsApi } from '@store/services/documents'
import { IDENTIFICATION_FILES_API_KEY, identificationFilesApi } from '@store/services/identificationFiles'
import { IDENTIFICATION_FILES_NON_USER_API_KEY, identificationFilesNonUserApi } from '@store/services/identificationFilesNonUser'
import { LAWDOCS_API_REDUCER_KEY, lawdocsApi } from './services/lawdocs'
import { BOOKING_METADATA_REDUCER_KEY, bookingMetadataApi } from './services/bookingMetadata'
import { BOOKING_METADATA_NON_USER, bookingMetadataNonUserApi } from './services/bookingMetadataNonUser'

const rootReducer = combineReducers({
  [USER_API_REDUCER_KEY]: userApi.reducer,
  [PRODUCTS_API_REDUCER_KEY]: productsApi.reducer,
  [BASKET_API_REDUCER_KEY]: basketApi.reducer,
  [NOTIFICATIONS_API_REDUCER_KEY]: notificationsApi.reducer,
  [PARTNER_API_REDUCER_KEY]: partnerApi.reducer,
  [PAYMENT_API_REDUCER_KEY]: paymentApi.reducer,
  [ONBOARDING_API_REDUCER_KEY]: onboardingApi.reducer,
  [DOCUMENTS_API_REDUCER_KEY]: documentsApi.reducer,
  [IDENTIFICATION_FILES_API_KEY]: identificationFilesApi.reducer,
  [IDENTIFICATION_FILES_NON_USER_API_KEY]: identificationFilesNonUserApi.reducer,
  [TASK_API_REDUCER_KEY]: taskApi.reducer,
  [BOOKING_API_REDUCER_KEY]: bookingApi.reducer,
  [ECOMMERCE_API_REDUCER_KEY]: ecommerceApi.reducer,
  [LAWDOCS_API_REDUCER_KEY]: lawdocsApi.reducer,
  [BOOKING_METADATA_REDUCER_KEY]: bookingMetadataApi.reducer,
  [BOOKING_METADATA_NON_USER]: bookingMetadataNonUserApi.reducer,
  app: appReducer,
  onboarding: onboardingReducer,
  basket: basketReducer,
  cart: cartSlice,
  modal: modalReducer,
  alert: alertReducer,
  lawdocs: lawdocsSlice
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
