import { getServiceUrlFor } from '@testavivadk/utilities'

export * from './userTasks'
export * from './analytics'
export { applicationSpaRoutes } from './routes'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any

export const applicationUrl = import.meta.env.MODE === 'development' ? (import.meta.env.VITE_APPLICATION_URL as string) : getServiceUrlFor(window.location.origin, 'app') as string
export const paymentUrl = import.meta.env.MODE === 'development' ? (import.meta.env.VITE_PAYMENT_API_URL as string) : getServiceUrlFor(window.location.origin, 'payment') as string
export const frontPageUrl = import.meta.env.MODE === 'development' ? (import.meta.env.VITE_FRONTEND_URL as string) : getServiceUrlFor(window.location.origin, 'frontend') as string
export const tasksUrl = import.meta.env.MODE === 'development' ? (import.meta.env.VITE_TASKS_URL as string) : getServiceUrlFor(window.location.origin, 'tasks') as string
export const bookingUrl = import.meta.env.MODE === 'development' ? (import.meta.env.VITE_BOOKING_URL as string) : getServiceUrlFor(window.location.origin, 'booking') as string
export const ecommerceUrl = (import.meta.env.MODE === 'development' || import.meta.env.MODE === 'qa')
  ? import.meta.env.VITE_ECOMMERCE_URL as string ?? ''
  : getServiceUrlFor(window.location.origin, 'ecommerce') as string
export const supportUrl = 'https://support.testaviva.dk'
export const lawdocsUrl = import.meta.env.MODE === 'development' ? (import.meta.env.VITE_LAWDOCS_URL as string) : getServiceUrlFor(window.location.origin, 'lawdocs') as string

window.urls = {
  applicationUrl, paymentUrl, tasksUrl, bookingUrl
}

export const authTokenName = 'token'
export const passwordlessTokenName = 'passwordless_link_token'
export const fileDownloadUrlParam = 'download_file_url'
export const authUserType = 'X-Auth-Type'

export const unleashFlagKeys = {
  myOverviewPage: 'MyOverviewPage',
  ssnField: 'SsnField',
  disableTasksService: 'disableTasksService',
  basketBookingWarning: 'basketBookingWarning',
  isRebookingDisabled: 'isRebookingDisabled'
}
