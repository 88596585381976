import { FC } from 'react'

import { useSelector, shallowEqual } from 'react-redux'
import { RootState } from '@store/rootReducer'

import { List, Divider } from '@mui/material'

import DrawerHeader from './components/DrawerHeader'
import ListItem from './components/ListItem'

import {
  Home,
  Assignment,
  NoteAdd,
  ContactSupport,
  Logout,
  Person2,
  LocalOffer,
  ShoppingBag,
} from '@mui/icons-material'

import { StyledDrawer } from './Drawer.styles'

import { applicationUrl, applicationSpaRoutes, supportUrl } from '@constants'
import { useFlag } from '@unleash/proxy-client-react'

const menuItems = [
  {
    itemKey: 'myOverview',
    icon: <Home />,
    href: applicationSpaRoutes.myOverview,
  },
  {
    itemKey: 'myDocuments',
    icon: <Assignment />,
    href: applicationSpaRoutes.myDocuments,
  },
  {
    itemKey: 'createDocument',
    icon: <NoteAdd />,
    href: applicationSpaRoutes.createDocument,
  },
  {
    itemKey: 'orders',
    icon: <ShoppingBag />,
    href: applicationSpaRoutes.orders,
    hasFeatureFlag: 'EnableEcommerceOrders',
  },
  {
    itemKey: 'support',
    icon: <ContactSupport />,
    onClick: () => {
      window.open(supportUrl, '_blank')
    },
  },
]

const userMenuItems = [
  {
    itemKey: 'myProfile',
    icon: <Person2 />,
    href: applicationSpaRoutes.profileEdit,
  },
  {
    itemKey: 'referralFactory',
    icon: <LocalOffer />,
    href: applicationSpaRoutes.referralFactory,
  },
  {
    itemKey: 'logout',
    icon: <Logout />,
    onClick: () => {
      window.location.href = `${applicationUrl}/user/logout`
    },
  },
]

const Drawer: FC = () => {
  const { isDrawerOpen } = useSelector((state: RootState) => ({ isDrawerOpen: state.app.isDrawerOpen }), shallowEqual)

  const hasFeatureFlagEnabled = (flag: string) => {
    return useFlag(flag)
  }

  return (
    <StyledDrawer variant="permanent" open={isDrawerOpen}>
      <DrawerHeader />
      <Divider />
      <List>
        {menuItems.map((item) =>
          item.hasFeatureFlag ? (
            hasFeatureFlagEnabled(item.hasFeatureFlag) && <ListItem key={item.itemKey} {...item} />
          ) : (
            <ListItem key={item.itemKey} {...item} />
          ),
        )}
      </List>
      <Divider />
      <List>
        {userMenuItems.map((item) => (
          <ListItem key={item.itemKey} {...item} />
        ))}
      </List>
    </StyledDrawer>
  )
}

export default Drawer
