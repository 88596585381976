import { memo } from 'react'

import FlashMessage from '@features/FlashMessage'
import Modals from '@features/Modals'
import PurchaseSidebar from '@features/PurchaseSidebar'
import OpenPdfFileModal from '@features/OpenPdfFileModal/OpenPdfFileModal'
import SharedEmailCheck from '@features/SharedEmailCheck'
import CartSidebar from '@features/CartSidebar'
import Analytics from '@components/Analytics'
import LayoutGrid from './components/LayoutGrid/LayoutGrid'

const NewLayout = () => (
  <>
    <PurchaseSidebar />
    <CartSidebar />
    <Modals />
    <FlashMessage />
    <OpenPdfFileModal />
    <SharedEmailCheck />
    <Analytics />
    <LayoutGrid />
  </>
)

export default memo(NewLayout)
