import { FC, useEffect } from 'react'

import { useLocation, Outlet } from 'react-router-dom'
import { useGetUserDetailsQuery } from '@store/services/user'

import {
  Box,
  Toolbar,
} from "@mui/material"
import Header from '../Header'
import Drawer from '../Drawer'
import Footer from '@frontend-components/components/Footer'

const MainLayout: FC = () => {
  const { pathname } = useLocation()

  const { data: userDetailsData } = useGetUserDetailsQuery()
  const isAuthenticated = userDetailsData?.authenticated ?? false

  useEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0 })
  }, [pathname])

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Header isAuthenticated={isAuthenticated} />
      <Box sx={{ display: "flex", flexGrow: 1 }}>
        {isAuthenticated && <Drawer />}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            transition: "margin-left 0.3s",
          }}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
      <Box sx={{ position: 'relative', zIndex: 1201 }}>
        <Footer />
      </Box>
    </Box>
  )
}

export default MainLayout
