import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from '@store/middleware'

import { applicationUrl } from '@constants'
import { type IAttachment } from '@testavivadk/common-tools/types'
import { type IDocument, type ICourt } from '@types'
import { Products, RegistrationType, SignatureType } from '@testavivadk/common-tools/constants'

export const DOCUMENTS_API_REDUCER_KEY = 'documentsApi'
export const documentsApi = createApi({
  reducerPath: DOCUMENTS_API_REDUCER_KEY,
  baseQuery: baseQueryWithAuth({
    baseUrl: applicationUrl
  }),
  tagTypes: ['MY_PAGE_DOCUMENTS'],
  endpoints: (builder) => ({
    getDocuments: builder.query<IDocument[], string | undefined>({
      query: (query = '') => ({
        url: `/api/documents${query}`,
        method: 'GET'
      }),
      providesTags: ['MY_PAGE_DOCUMENTS']
    }),
    getDocument: builder.query<IDocument, IDocument['id']>({
      query: (id) => ({
        url: `/api/document/${id}`,
        method: 'GET'
      })
    }),
    getDocumentGenericData: builder.query<IDocument, Products>({
      query: (productType) => ({
        url: `/api/documents/type/${productType}`,
        method: 'GET'
      })
    }),
    deleteDocument: builder.mutation<void, IDocument['id']>({
      query: (id) => ({
        url: `/api/document/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['MY_PAGE_DOCUMENTS']
    }),
    uploadDocument: builder.mutation<void, FormData>({
      query: (document) => ({
        url: '/api/documents/upload',
        method: 'POST',
        body: document,
        responseHandler: 'text'
      }),
      invalidatesTags: ['MY_PAGE_DOCUMENTS']
    }),
    getAttachments: builder.query<IAttachment[], void>({
      query: () => ({
        url: '/api/attachments',
        method: 'GET'
      })
    }),
    confirmDocument: builder.mutation<void, { id: IDocument['id'] }>({
      query: ({ id }) => ({
        url: `/api/document/${id}/confirm`,
        method: 'PUT'
      })
    }),
    getCourts: builder.query<ICourt[], void>({
      query: () => ({
        url: '/api/documents/registration/courts',
        method: 'GET'
      })
    }),
    postCourtRegistration: builder.mutation<void, {
      id: IDocument['id']
      court: string | undefined | null,
      type: RegistrationType | undefined | null
    }>({
      query: (data) => ({
        url: `/api/document/${data.id}/registration`,
        method: 'POST',
        body: data
      })
    }),
    postSignatureMethod: builder.mutation<void, {
      id: IDocument['id']
      signatureType: SignatureType
    }>({
      query: (data) => ({
        url: `/api/document/${data.id}/select-signature`,
        method: 'POST',
        body: data
      })
    })
  })
})

export const {
  useGetDocumentsQuery,
  useLazyGetDocumentsQuery,
  useDeleteDocumentMutation,
  useUploadDocumentMutation,
  useGetAttachmentsQuery,
  useConfirmDocumentMutation,
  useLazyGetDocumentQuery,
  useGetDocumentQuery,
  useGetDocumentGenericDataQuery,
  useLazyGetCourtsQuery,
  usePostCourtRegistrationMutation,
  usePostSignatureMethodMutation
} = documentsApi
