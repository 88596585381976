export interface ReferenceAuthenticationModalProps {
  token: string
  onClose: () => void
}

export type SignUpFieldsType = Partial<{
  conditions: boolean
  email: string
  password: string
  repeatPassword: string
  ssn?: string
}>

export type FilteredConsentsType = {
  id: string
  text: string
  required: boolean
  consentType: string
  error: string
}

export const REFERENCE_AUTH_MODAL_STORAGE_KEY = 'reference_auth_modal_storage_key'
