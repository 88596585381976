import { FC, useEffect, useState } from 'react'

import { useLocation, useSearchParams } from 'react-router-dom'

import Alert from '@frontend-components/components/Alert'

import { AlertType } from '@frontend-components/components/Alert/Alert.types'

const FlashMessage: FC = () => {
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const [type, setType] = useState<AlertType | null>(null)
  const [message, setMessage] = useState('')

  useEffect(() => {
    const flashSuccess = searchParams.get('flashSuccess')
    const flashWarning = searchParams.get('flashWarning')
    const flashError = searchParams.get('flashError')

    if (flashSuccess != null) {
      setType('success')
      setMessage(flashSuccess)
    } else if (flashWarning != null) {
      setType('warning')
      setMessage(flashWarning)
    } else if (flashError != null) {
      setType('error')
      setMessage(flashError)
    }
  }, [pathname, searchParams])

  return type != null ? <Alert data-cy={`flash-message-alert-${type}`} type={type} onClose={() => setType(null)}><p data-cy="flash-message-content">{message}</p></Alert> : null
}

export default FlashMessage
