import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithAuth } from '@store/middleware'

import { applicationUrl } from '@constants'
import { type IBookingMetadata } from '@types'

export const BOOKING_METADATA_REDUCER_KEY = 'bookingMetadata'
export const bookingMetadataApi = createApi({
  reducerPath: BOOKING_METADATA_REDUCER_KEY,
  baseQuery: baseQueryWithAuth({ baseUrl: applicationUrl }),
  endpoints: (builder) => ({
    getBookingMetadata: builder.query<IBookingMetadata[], void>({
      query: () => ({
        url: '/api/user/booking-metadata',
        method: 'GET'
      })
    }),
    postBookingMetadata: builder.mutation<void, IBookingMetadata['data']>({
      query: (metadata) => ({
        url: '/api/user/booking-metadata',
        method: 'POST',
        body: { type: 'input_meeting_data', data: metadata },
      })
    }),
    putBookingMetadata: builder.mutation<void, { id: IBookingMetadata['id'], metadata: IBookingMetadata['data'] }>({
      query: ({ id, metadata }) => ({
        url: `/api/user/booking-metadata/${id}`,
        method: 'PUT',
        body: { type: 'input_meeting_data', data: metadata },
      })
    })
  })
})

export const {
  useGetBookingMetadataQuery,
  useLazyGetBookingMetadataQuery,
  usePostBookingMetadataMutation,
  usePutBookingMetadataMutation
} = bookingMetadataApi