import { FC, PropsWithChildren } from 'react'

import { DrawerHead } from './DrawerHeader.styles'

const DrawerHeader: FC<PropsWithChildren> = ({ children }) => (
  <DrawerHead>
    {children}
  </DrawerHead>
)

export default DrawerHeader
