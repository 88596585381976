import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { applicationUrl } from '@constants'
import { authHeaderName } from '@testavivadk/common-tools/constants'

import { type IBookingMetadata } from '@types'

export const BOOKING_METADATA_NON_USER = 'bookingMetadataNonUser'
export const bookingMetadataNonUserApi = createApi({
  reducerPath: BOOKING_METADATA_NON_USER,
  baseQuery: fetchBaseQuery({ baseUrl: applicationUrl }),
  endpoints: (builder) => ({
    getBookingMetadataNonUser: builder.query<IBookingMetadata[], string>({
      query: (token) => ({
        url: '/api/user/booking-metadata',
        method: 'GET',
        headers: { [authHeaderName]: token }
      })
    }),
    postBookingMetadataNonUser: builder.mutation<void, { token: string, metadata: IBookingMetadata['data'] }>({
      query: ({ token, metadata }) => ({
        url: '/api/user/booking-metadata',
        method: 'POST',
        body: { type: 'input_meeting_data', data: metadata },
        headers: { [authHeaderName]: token }
      })
    }),
    putBookingMetadataNonUser: builder.mutation<void, { token: string, id: IBookingMetadata['id'], metadata: IBookingMetadata['data'] }>({
      query: ({ token, id, metadata }) => ({
        url: `/api/user/booking-metadata/${id}`,
        method: 'PUT',
        body: { type: 'input_meeting_data', data: metadata },
        headers: { [authHeaderName]: token }
      })
    })
  })
})

export const {
  useGetBookingMetadataNonUserQuery,
  useLazyGetBookingMetadataNonUserQuery,
  usePostBookingMetadataNonUserMutation,
  usePutBookingMetadataNonUserMutation
} = bookingMetadataNonUserApi