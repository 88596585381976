import { FC } from 'react'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { Link } from 'react-router-dom'
import { Link as MuiLink } from '@mui/material'

import LogoFull from '@public/images/svgs/logo-full.svg'
import LogoSmall from '@public/images/svgs/logo-small.svg'
import { applicationSpaRoutes } from '@constants'
import { themeColors } from '@testavivadk/css-tools/css/themeColors'

const Logo: FC<{ color?: string }> = ({ color = themeColors.darkTeal }) => {
  const theme = useTheme()
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <MuiLink
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'center'
      }}
      component={Link}
      to={applicationSpaRoutes.myOverview}
      aria-label="Testaviva"
      data-analytics="header.link.logo"
    >
      {isBelowMd ? (
        <LogoSmall fill={color} width="32" height="20" viewBox="0 0 32 20" />
      ) : (
        <LogoFull fill={color} width="134" height="16" viewBox="0 0 134 16" />
      )}
    </MuiLink>
  )
}

export default Logo
