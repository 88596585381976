export const applicationSpaRoutes = {
  onboardingIndex: '/onboarding',
  onboardingValues: '/onboarding/værdier',
  onboardingScore: '/onboarding/anbefaling',
  profileIndex: '/profile',
  profileEdit: '/profile/edit',
  profileBookings: '/profile/bookings',
  profilePayments: '/profile/payments',
  profileChangePassword: '/profile/change-password',
  resendActivationMail: '/gensend-aktiveringsmail',
  consentBorgerDK: '/samtykke-borger-dk',
  createDocument: '/opret-dokument',
  basket: '/basket',
  completePurchase: '/checkout-completed',
  receipt: '/receipt',
  login: '/login',
  userActivation: '/user-activation',
  notifications: '/notifications',
  myOverview: '/mit-overblik',
  myDocuments: '/my-documents',
  approveDocument: '/my-documents/approve',
  mitIdVerification: '/opret-dokument/adgang',
  uploadId: '/upload-id',
  lawdocs: '/lawdocs',
  products: '/produkter',
  referralFactory: '/referral-factory',
  orders: '/ordre',
  getReadyForMeeting: '/bliv-klar-til-møde',
}
