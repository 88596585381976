import { styled } from '@mui/material/styles'

import { AppBar, IconButton } from '@mui/material'

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  p: 0,
  m: 0,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
}))

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: '0.5rem',
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}))