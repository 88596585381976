import {
  FC,
  useRef,
  useState
} from 'react'

import { useTranslation } from 'react-i18next'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useGetOtherUsersQuery, useGetUserDetailsQuery } from '@store/services/user'

import {
  Button, Link as MuiLink, MenuItem, Menu as MuiMenu
} from '@mui/material'
import { Link } from 'react-router-dom'

import { Person, Logout, ExpandMore } from '@mui/icons-material'
import { applicationUrl, applicationSpaRoutes } from '@constants'

const UserMenu: FC<{ analyticsPostfix?: string, color?: string }> = ({ analyticsPostfix = '', color = '' }) => {
  const { data: userDetailsData } = useGetUserDetailsQuery()
  const { data: otherUsersData } = useGetOtherUsersQuery()

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const menuButtonRef = useRef<HTMLButtonElement>(null)
  const { t } = useTranslation()

  const theme = useTheme()
  const isAboveSm = useMediaQuery(theme.breakpoints.up('sm'))

  const handleMouseOver = () => {
    if (!isMouseOver) {
      setIsMenuOpen(true)
      setIsMouseOver(true)
    } else {
      setIsMouseOver(false)
    }
  }

  const handleCloseMenu = () => {
    setIsMenuOpen(false)
    setIsMouseOver(true)
  }

  return (
    <>
      <Button
        title={t('appHeader.userMenu.myProfile') as string}
        data-cy="user-menu-button"
        sx={{
          display: 'flex', gap: 1, alignItems: 'center', textTransform: 'none', height: '100%', color
        }}
        ref={menuButtonRef}
        onClick={() => setIsMenuOpen((prevOpen) => !prevOpen)}
        data-analytics={`header.button.userMenuExpand${analyticsPostfix}`}
        onMouseOver={handleMouseOver}
        onMouseLeave={() => setIsMouseOver(false)}
        startIcon={<Person />}
      >
        {isAboveSm && userDetailsData?.fullname}
        <ExpandMore
          sx={[
            isMenuOpen && {
              transform: 'rotate(180deg)'
            }
          ]}
        />
      </Button>
      <MuiMenu
        anchorEl={menuButtonRef.current}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        disableScrollLock
        data-cy="user-menu-list"
      >
        <div onMouseLeave={() => setIsMenuOpen(false)}>
          <MenuItem>
            <MuiLink
              sx={{ display: 'flex', gap: 2, alignItems: 'center', flex: 1 }}
              component={Link}
              to={applicationSpaRoutes.profileEdit}
              underline="none"
              data-analytics={`header.link.userMenuEditProfile${analyticsPostfix}`}
            >
              <Person />
              {t('appHeader.userMenu.myProfile')}
            </MuiLink>
          </MenuItem>
          {otherUsersData?.map((otherUser) => (
            <MenuItem
              key={otherUser.id}
            >
              <MuiLink
                sx={{ display: 'flex', gap: 2, alignItems: 'center', flex: 1 }}
                href={`${applicationUrl}/user/switch/${otherUser.id}?redirect_url=${window.location.href}`}
                underline="none"
                data-analytics={`header.link.userMenuSwitchUser${analyticsPostfix}`}
              >
                <Person />
                {otherUser.fullname}
              </MuiLink>
            </MenuItem>
          ))}
          <MenuItem>
            <MuiLink
              sx={{ display: 'flex', gap: 2, alignItems: 'center', flex: 1 }}
              href={`${applicationUrl}/user/logout`}
              data-analytics={`header.link.userMenuLogout${analyticsPostfix}`}
              underline="none"
            >
              <Logout />
              {t('appHeader.userMenu.logOut')}
            </MuiLink>
          </MenuItem>
        </div>
      </MuiMenu>
    </>
  )
}

export default UserMenu
