import { FC } from 'react'

import { useGetBasketQuery } from '@store/services/basket'
import { useTranslation } from 'react-i18next'
import { useGetUserDetailsQuery } from '@store/services/user'
import { useFlag } from '@unleash/proxy-client-react'
import { useDispatch } from 'react-redux'
import { setPurchaseSidebarOpen } from '@store/slices/basketSlice'
import { setCartSidebarOpen } from '@store/slices/cartSlice'

import { Box, Button } from '@mui/material'
import { ShoppingCart } from '@mui/icons-material'
import { themeColors } from '@testavivadk/css-tools/css/themeColors'
import { useGetCartQuery } from '@store/services/ecommerce'

const CartButton: FC<{ analyticsPostfix?: string }> = ({ analyticsPostfix = '' }) => {
  const isEcommerceUserCartEnabled = useFlag('EcommerceUserCart')

  const { data: userDetailsData } = useGetUserDetailsQuery()
  const { data: cartData } = useGetCartQuery({ userId: Number(userDetailsData?.id) }, { skip: !isEcommerceUserCartEnabled })
  const { data: basketData } = useGetBasketQuery(null)

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleClickBasket = () => {
    if (isEcommerceUserCartEnabled) {
      dispatch(setCartSidebarOpen(true))
      return
    }
    dispatch(setPurchaseSidebarOpen(true))
  }

  const isCartHasItems = isEcommerceUserCartEnabled ? (cartData?.lines.length ?? 0) > 0 : (basketData?.items.length ?? 0) > 0

  return (
    <Button
      variant="contained"
      color="secondary"
      sx={{
        height: '100%',
        borderRadius: 0
      }}
      id="toggle-basket"
      onClick={handleClickBasket}
      title={t('basket.buttonTitle') as string}
      data-analytics={`header.button.purchaseSidebarToggle${analyticsPostfix}`}
      data-testid="purchase-sidebar-toggle-button"
    >
      {isCartHasItems ? (
        <Box
          component="span"
          sx={{
            position: 'absolute',
            backgroundColor: 'white',
            borderRadius: '50%',
            color: themeColors.warmPink,
            width: '14px',
            top: '15%',
            right: '11px',
            fontSize: '0.625rem',
            height: '14px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold'
          }}
        >
          {isEcommerceUserCartEnabled ? cartData?.lines.length : basketData?.items.length}
        </Box>
      ) : null}
      <ShoppingCart sx={{ fontSize: '1.2rem' }} />
    </Button>
  )
}

export default CartButton
