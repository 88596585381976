import { FC } from 'react'

import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { RootState } from '@store/rootReducer'
import { setDrawerOpen } from '@store/slices/appSlice'

import { Toolbar, Box } from '@mui/material'
import CartButton from '@features/Header/components/CartButton'
import Notifications from '@features/Header/components/Notifications'
import UserMenu from '@features/Header/components/UserMenu'

import MenuIcon from '@mui/icons-material/Menu'
import Logo from '@features/Header/components/Logo'
import { StyledAppBar, StyledIconButton } from './Header.styles'

const Header: FC<{ isAuthenticated: boolean }> = ({ isAuthenticated }) => {
  const dispatch = useDispatch()
  const { isDrawerOpen } = useSelector((state: RootState) => ({ isDrawerOpen: state.app.isDrawerOpen }), shallowEqual)
  const analyticsPostfix = '-fullWidthLayout'

  return (
    <StyledAppBar position="fixed">
      <Toolbar sx={{
        justifyContent: 'space-between',
        justifyItems: 'center',
        paddingLeft: '0 !important'
      }}
      >
        <Toolbar sx={{ display: 'flex', alignItems: 'center' }}>
          {isAuthenticated && (
            <StyledIconButton
              color="inherit"
              aria-label="toggle drawer"
              onClick={() => dispatch(setDrawerOpen(!isDrawerOpen))}
              edge="start"
              data-analytics="global.iconButton.toggleDrawer"
              sx={{ mr: 0.5 }}
            >
              <MenuIcon />
            </StyledIconButton>
          )}
          <Logo color="white" />
        </Toolbar>
        {isAuthenticated && (
          <Box
            sx={{
              display: 'flex',
              height: '62px'
            }}
          >
            <Box display="flex" alignItems="center">
              <CartButton analyticsPostfix={analyticsPostfix} />
              <Notifications color="white" analyticsPostfix={analyticsPostfix} />
              <UserMenu color="white" analyticsPostfix={analyticsPostfix} />
            </Box>
          </Box>
        )}
      </Toolbar>
    </StyledAppBar>
  )
}

export default Header
