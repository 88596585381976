import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import useAnalytics from '@hooks/Analytics/useAnalytics'
import { useSelector, shallowEqual } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { RootState } from '@store/rootReducer'

import {
  ListItem as MuiListItem, ListItemButton, ListItemIcon, ListItemText
} from '@mui/material'

const ListItem: FC<{
  href?: string,
  icon: JSX.Element,
  itemKey: string
  onClick?: () => void
}> = ({
  href, itemKey, icon, onClick
}) => {
  const { track } = useAnalytics()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const { isDrawerOpen } = useSelector((state: RootState) => ({ isDrawerOpen: state.app.isDrawerOpen }), shallowEqual)

  const handleClick = () => {
    track(`drawer.listItemButton.${itemKey}`)

    if (typeof onClick === 'function') {
      onClick()
      return
    }
    if (href != null) {
      navigate(href)
    }
  }

  return (
    <MuiListItem
      disablePadding
      sx={{ display: 'block' }}
    >
      <ListItemButton
        selected={pathname === href}
        sx={{
          minHeight: 48,
          justifyContent: isDrawerOpen ? 'initial' : 'center',
          px: 2.5
        }}
        onClick={handleClick}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: isDrawerOpen ? 3 : 'auto',
            justifyContent: 'center'
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={t(`navigation.${itemKey}`)}
          sx={{ opacity: isDrawerOpen ? 1 : 0 }}
        />
      </ListItemButton>
    </MuiListItem>
  )
}

export default ListItem
